<template>
	<div>
		<h2>{{ $t('monte.planning_ia') }}</h2>
		<div class="row mb-4">
			<div class="col-12">
				<div class="box">
					<h4 class="mb-4"> {{ $t('monte.lieu_residence') }} </h4>
					<e-select
						v-model="residence_selected"
		                :options="residences"
		                :searchable="true"
		                label="lieu_label"
		                :loading="loadResidence"
					>	
						<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>

					<b-form-checkbox id="planning_default" v-model="planning_default"> <label for="planning_default">{{ $t("monte.default_season") }}</label></b-form-checkbox>
				</div>
			</div>
		</div>
		<div class="box">
			<div class="row">
				<div class="col-12">
					<h4 class="mb-4"> {{ $t('monte.choix_creneaux') }} </h4>
               		<draggable class="list-group list-group-left" :list="creneaux" v-bind="dragOptionsSelectedColumns" group="colonne">
               			<div class="list-group-item align-items-center" v-for="(creneau, index) in creneaux" :key="index">
							<div class="monte_creneaux">
								<div class="form-row align-items-center">
									<div class="col-lg-12 col-xl-auto mr-auto">
										<label class="mb-0" :for="'creneau_' + index">{{ $t('monte.creneau') }} {{ index+1 }}</label>
									</div>
									<div class="col-5 col-md-3 col-sm-auto">
							            <input
							                class="form-control"
							                v-model="creneau.start"
							                type="text"
							                required
							            />
							        </div>
									<div class="col-auto mx-auto">
							            {{ $t('global.a') }}
							        </div>
									<div class="col-5 col-md-3 col-sm-auto mb-2 mb-md-0">
							            <input
							                class="form-control"
							                v-model="creneau.end"
							                type="text"
							                required
							            />
							        </div>
							        <div class="col">
							            <e-select
											v-model="creneau.stallion.planninghorse_stallion"
							                :options="stallion_dispo"
							                :searchable="true"
							                label="horse_nom"
										>	
											<template slot="singleLabel" slot-scope="{ option }">{{ option.horse_nom }}</template>
											<template slot="noOptions">{{ $t('global.list_empty') }}</template>
										</e-select>
									</div>
							        <div class="colauto ml-auto">
							            <b-button @click="deleteCreneau(index)" variant="secondary btn-block">
											<font-awesome-icon :icon="['fal', 'trash-alt']" />
										</b-button>
									</div>
									<div class="col-auto ml-auto">
										<div class="btn btn-secondary ml-auto order-md-last">
							                <font-awesome-icon :icon="['fal', 'sort-alt']" />
							            </div>
							        </div>
								</div>
							</div>
						</div>
					</draggable>
					<div class="text-center mt-4">
						<button class="btn btn-secondary rounded-pill mr-3" @click="addCreneau">
							<font-awesome-icon :icon="['fal', 'plus']" /> {{ $t('global.ajouter') }}
						</button>

						<ErrorAlert v-if="error_stallion" class="mt-2" messageI18n="monte.error_planning_stallion" />
					</div>
				</div>
	        </div>
		</div>

			<div class="text-center mb-5">
                <b-button pill variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.enregistrer") }}</b-button>
            </div>
	</div>
</template>

<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js"
    import PlanningMonte from "@/mixins/PlanningMonte.js"
    import Lieux from "@/mixins/Lieux.js"
	import Common from '@/assets/js/common'
	import _isEmpty from 'lodash/isEmpty'
    import draggable from 'vuedraggable'

	export default {
		name: 'PlanningIA',
		props: ['season_id'],
		mixins: [Navigation, PlanningMonte, Lieux],
		data () {
			return {
				processing: false,
				creneaux: [],
				delete_creneau: null,
				stallion_dispo: [],

				loadResidence: false,
				residences: [],
				residence_selected: {},
				planning_default: false,

                dragOptionsSelectedColumns: {
                    animation: 200
                },

                error_stallion: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loadResidence = true
				this.residences = await this.getResidences()
				this.loadResidence = false

				this.stallion_dispo = await this.getHorsesStallion(false)

				const residence = await this.getResidencePlanningDefault(this.season_id, "IA")

				if(residence) {
					this.residence_selected = this.residences.find(lieu => lieu.lieu_id == residence.planning_lieu)
				}
			},

			async loadPlanning() {
				this.creneaux = []
				this.planning_default = false

				const planning = await this.getPlanning(this.season_id, 'IA', null, this.residence_selected.lieu_id)

				for(let i in planning.planning_schedule) {
					const stallion = planning.planning_horse.find(horse => horse.planninghorse_schedule.schedule_id == planning.planning_schedule[i].schedule_id)
					this.creneaux.push({
						index: Common.getRandomInt(99999),
						id: planning.planning_schedule[i].schedule_id,
						start: planning.planning_schedule[i].schedule_start,
						end: planning.planning_schedule[i].schedule_end,
						stallion: stallion
					})
				}
				this.planning_default = planning.planning_default
			},

			async checkForm() {
				this.processing = true
				this.error_stallion = false

				let error = false
				let creneaux = []

				for(let i in this.creneaux) {
					if(!this.creneaux[i].start || !this.creneaux[i].end) {
						error = true
					}
					this.creneaux[i].order = i
					creneaux.push(this.creneaux[i])
				}

				if(!this.planning_default) 
					this.planning_default = false

				if(error) {
					this.failureToast("monte.planning_bad_config")
					this.processing = false
					return 
				}

				let sch_wh_stallion = creneaux.filter(cr => !cr.stallion.planninghorse_stallion)

				if(sch_wh_stallion.length > 0) {
					this.processing = false
					this.error_stallion = true
					return false
				}

				const res = await this.saveParametrePlanningIA(this.season_id, this.residence_selected.lieu_id, creneaux, this.planning_default)
				this.processing = false
				if(res) {
					this.successToast("toast.info_modif_succes")
				}
			},

			addCreneau() {
				this.creneaux.push({
					index: Common.getRandomInt(99999),
					stallion: {}
				})
			},

			deleteCreneau(index) {
				this.delete_creneau = index
			}
		},

		watch: {
			delete_creneau (index) {
				if(index > -1) 
					this.creneaux.splice(index, 1)
				this.delete_creneau = -1
			},
			residence_selected () {
				this.loadPlanning()
			}
		},

		components: {
            ConfigCreneauxEtalon: () => import('@/components/Saison/ConfigCreneauxEtalon'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            draggable
		}
	}

</script>